import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { UNCERTAINTY_LINK } from "../master/staticTable/editTable";
import {
  calculateUncertaintyContribution,
  generateTableData,
  resolveUncertaintyValue,
  convertUnit,
  convertUnitWrap,
} from "./utils";
import { isCovertPossible } from "../../utils/components/unitConvertor";
import { toast } from "react-toastify";
import { min } from "date-fns";

const UNIT_SEPARATOR = "#";
const UNIT_ROW_VALUE_PREFIX = "_unit_";
const ROW_HEADER_VALUE_PREFIX = "_rh_";

const MEAN_PECISION = 4;
const X_MINUS_X_BAR_PECISION = 4;
const X_MINUS_X_BAR_SQUARE_PECISION = X_MINUS_X_BAR_PECISION + 4;
const SD_PRECISION = X_MINUS_X_BAR_PECISION + 4;
const MSD_PRECISION = X_MINUS_X_BAR_PECISION + 4;

const trimPrecision = (value) => {
  // Convert value to string if it's not already a strin
  value = String(value);
  let unit = value.split("#")[1];
  value = value.split("#")[0];

  // Check if the value is in exponential format
  if (value.includes("e") || value.includes("E")) {
    // Parse the number to preserve exponent notation
    let parsedValue = parseFloat(value);
    // Convert back to exponential format with 7 precision
    value = parsedValue.toExponential(7);
  } else {
    // Convert the value to number
    let numValue = Number(value);
    // Check if the number has more than 7 significant digits
    if (numValue.toString().length > 7) {
      // Convert number to string and trim precision
      let trimmedValue = numValue.toFixed(7);
      // Convert back to number and return
      value = Number(trimmedValue);
    }
  }

  return String(value) + (unit ? "#" + unit : "");
};

const fetchTables = (tableId) => {
  return axiosWithToken
    .get(BASE_URL + `datasheetStaticTables?_where=(id,eq,${tableId})`)
    .then((res) => [["table", res.data[0]]])
    .catch((err) =>
      console.log("datasheetStaticTables data fetching error : ", err)
    );
};

const fetchReadings = (datasheetId, tableId) => {
  return axiosWithToken
    .get(
      BASE_URL +
        `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})~and(tableId,eq,${tableId})`
    )
    .then((res) => [["reading", res.data]])
    .catch((err) =>
      console.log("datasheetStaticReadings data fetching error : ", err)
    );
};

const fetchUncertaintiesMap = (instrumentId) => {
  return axiosWithToken
    .get(
      BASE_URL +
        `instruments?_fields=uncertaintyFactors,commonUncertaintyFactors&_where=(id,eq,${instrumentId})`
    )
    .then((res) => {
      let uMap = {};
      (res.data[0]?.uncertaintyFactors || "")
        .split("|")
        .map((e) =>
          e.split(":").map((e2, i) => (i === 0 ? e2 : (e2 || "").split(",")))
        )
        .forEach((e) => (uMap[e[0]] = e[1]));

      let commonUncertaintyFactors =
        res.data[0]?.commonUncertaintyFactors || "";
      commonUncertaintyFactors = commonUncertaintyFactors
        ? "0:" + commonUncertaintyFactors
        : null;
      if (commonUncertaintyFactors) {
        (commonUncertaintyFactors || "")
          .split("|")
          .map((e) =>
            e.split(":").map((e2, i) => (i === 0 ? e2 : (e2 || "").split(",")))
          )
          .forEach((e) => (uMap[e[0]] = e[1]));
      }
      return [["uncertaintiesMap", uMap]];
    })
    .catch((err) => console.log("instrument data fetching error : ", err));
};

const fetchCmcs = async (instrumentId, datasheetId) => {
  let query = `SELECT * FROM cmc where instrumentId=${instrumentId} and accreditationType = ( SELECT CASE WHEN calibrationType = 1 OR calibrationType = 3 THEN 1 ELSE calibrationType END AS calibrationType FROM srfInstruments WHERE id = ${datasheetId});`;
  let res = await axiosWithToken.post(BASE_URL + `dynamic`, { query });

  return [["cmcs", res.data]];
};

const fetchDatasheetDetails = (datasheetId) => {
  return axiosWithToken
    .get(BASE_URL + `datasheets?_where=(id,eq,${datasheetId})`)
    .then((res) => [["datasheetDetails", res.data[0]]])
    .catch((err) => console.log("datasheet data fetching error : ", err));
};

const getTypeAColumns = (table, second) => {
  return Object.entries(table)
    .map((map) => {
      if (
        (second && String(map[1]).match(/d+\d+/g)) ||
        (!second && String(map[1]).match(/m+\d+/g))
      ) {
        return map[0];
      }
    })
    .filter((val) => {
      if (val) return val;
    });
};

const parseCMCs = (cmcs) => {
  let newCmcs = [];
  cmcs.map((cmc) =>
    newCmcs.push({
      fromRange: (cmc.fromRange || "").split(UNIT_SEPARATOR),
      toRange: (cmc.toRange || "").split(UNIT_SEPARATOR),
      lowerCmc: (cmc.lowerCmc || "").split(UNIT_SEPARATOR),
      higherCmc: (cmc.higherCmc || "").split(UNIT_SEPARATOR),
      id: cmc.id,
      instrumentId: cmc.instrumentId,
      mode: cmc.mode,
      parameter: cmc.parameter,
      paratype: cmc.paratype,
      lc: cmc.lc,
    })
  );
  return newCmcs;
};

const parseTable = (table) => {
  let parsedTable = { ...table };
  parsedTable.conditionFormatting = JSON.parse(table.conditionFormatting);
  parsedTable.defaultConfiguration = JSON.parse(table.defaultConfiguration);
  return parsedTable;
};

const parseReadings = (readings) => {
  let cu = {};
  let parsedReadings = [];
  let index = 0;
  readings.map((e) => {
    if (e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX)) cu[e.tableId] = e;
    if (
      e["c1"]?.includes(UNIT_ROW_VALUE_PREFIX) ||
      e["c1"]?.includes(ROW_HEADER_VALUE_PREFIX)
    )
      return null;

    parsedReadings.push({});

    parsedReadings[index].unitMap = cu[e.tableId];

    Object.keys(e).map((e2, i) => {
      if ((e2.match(/c+\d+/g) || e2 === "uncertainty") && e[e2]) {
        let [value, unit] = e[e2].split("#");
        value = value.replace(/[$]+/, "");
        value = isNaN(value) || value === "" ? null : Number(value);
        parsedReadings[index][e2] = [value, unit];
      } else if (e2 === "standardRanges") {
        parsedReadings[index]["standardRanges"] = e[e2]
          ? e[e2].split(",").map((e3) => e3.split(":"))
          : [];
      } else if (e2 === "supportiveRanges") {
        parsedReadings[index]["supportiveRanges"] = e[e2]
          ? e[e2].split(":")
          : [];
      } else parsedReadings[index][e2] = e[e2];
    });
    index += 1;
  });
  return parsedReadings;
};

const fetchUncertaintyFactors = async (readings, uncertaintiesMap) => {
  let uf = Object.entries(uncertaintiesMap)
    .map((e) => e[1])
    .flat()
    .join(",");

  return axiosWithToken
    .get(BASE_URL + `uncertainty?_where=(id,in,${uf})`)
    .then(async (res) => [["uncertainty", res.data]])
    .catch((err) =>
      console.log("uncertainty factors data fetching error: ", err)
    );
};

const fetchSupprtiveRanges = (supportives) => {
  let ids = supportives?.map((s) => s[1]).filter((e) => e);
  if (!ids || ids.length <= 0) return [];
  return axiosWithToken
    .get(
      BASE_URL +
        `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_where=(sr.id,in,${ids})&_fields=sr.id,sr.standardId,sr.axialUniformity,sr.radialUniformity,sr.stability,s.standardName,s.stId`
    )
    .then((res) => {
      let srMap = {};
      res.data.map(
        (e) =>
          (srMap[e.sr_id] = {
            id: e.sr_id,
            standardId: e.sr_standardId,
            axialUniformity: e.sr_axialUniformity,
            radialUniformity: e.sr_radialUniformity,
            stability: e.sr_stability,
            standardName: e.s_standardName,
            stId: e.s_stId,
          })
      );
      return [["supportiveRanges", srMap]];
    })
    .catch((err) => [console.log("instrument data fetching error : ", err)]);
};

const fetchStandardRanges = async (standardRangeIds) => {
  if (!standardRangeIds || standardRangeIds.length <= 0) return [];
  standardRangeIds = standardRangeIds.join(",");
  let res = await axiosWithToken.get(
    BASE_URL +
      `xjoin?_join=sr.standardRanges,_j,s.standards&_on1=(sr.standardId,eq,s.id)&_fields=sr.id,sr.rangeName,s.masterrange&_where=(sr.id,in,${standardRangeIds})`
  );
  let srMap = {};
  res.data.forEach((e) => {
    srMap[e.sr_id] = {
      id: e.sr_id,
      fromRange: e.sr_rangeName?.split("|")?.[0],
      toRange: e.sr_rangeName?.split("|")?.[1],
      masterEQPRange: {
        from: e.s_masterrange?.split("|")?.[0],
        to: e.s_masterrange?.split("|")?.[1],
      }
    };
  });
  return srMap;
};

const processTypeARows = ({ inputs, unit }) => {
  if (!inputs) return {};
  inputs = inputs.filter((e) => e !== null && e !== undefined);

  if (!inputs || inputs?.length <= 0) return {};

  let mean = inputs.reduce((s, v) => s + v, 0) / inputs.length;
  mean = mean.toFixed(MEAN_PECISION);
  let rows = [];

  let x_xbar_sqr_sum = 0;
  inputs.map((e, i) => {
    rows.push([
      `${i + 1}`,
      `${e} ${unit}`,
      `${mean} ${unit}`,
      (e - mean).toFixed(X_MINUS_X_BAR_PECISION),
      Math.pow(e - mean, 2).toFixed(X_MINUS_X_BAR_SQUARE_PECISION),
    ]);
    x_xbar_sqr_sum += Number(rows[rows.length - 1][4]);
  });
  x_xbar_sqr_sum = x_xbar_sqr_sum.toFixed(X_MINUS_X_BAR_SQUARE_PECISION);

  let sd = Math.sqrt(x_xbar_sqr_sum / (inputs.length - 1));
  sd = sd.toFixed(SD_PRECISION);
  let meanOfStdDev = sd / Math.sqrt(rows.length);
  meanOfStdDev = meanOfStdDev.toFixed(MSD_PRECISION);
  if(isNaN(meanOfStdDev)){
    meanOfStdDev = 0
  }
  if(isNaN(sd)){
    sd = 0
  }
  rows.push([
    ["", 3],
    () => (
      <>
        (x-x̄)<sup>2</sup>
      </>
    ),
    x_xbar_sqr_sum,
  ]);

  sd = sd + "#" + unit;
  meanOfStdDev = meanOfStdDev + "#" + unit;

  rows.push([
    ["STANDARD DEVIATION OF THE MEAN (σ)", 4],
    `${sd.replace("#", " ")}`,
  ]);
  rows.push([
    ["MEAN OF STANDARD DEVIATION", 4],
    `${meanOfStdDev.replace("#", " ")}`,
  ]);
  rows.push([["DEGREES OF FREEDOM", 4], `${inputs.length - 1}`]);
  return { rows, mean, sd, meanOfStdDev, n: inputs.length };
};

const processTypeBRows = ({
  id,
  inputs,
  fallbackUnit,
  supportiveRanges,
  uncertaintiesMap,
  staticTable,
  readings,
  datasheetId,
  instrumentId,
  tableId,
}) => {
  if (!inputs) return {};

  let reverseMap = {};
  let standards = readings.standardRanges.map((e) => e);
  let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };
  let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];

  Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));

  let uncertaintyFactorRows = resolveUncertaintyRows(
    standards,
    inputs,
    uncertaintiesMap,
    id,
    fallbackUnit,
    supportiveRanges,
    readings,
    reverseMap,
    datasheetId,
    instrumentId,
    tableId,
    rangeCol
  );
  return {
    rows: uncertaintyFactorRows.map((uf, i) => {
      let uncertaintyFactor = uf[0];
      let uncertaintyFactorValue = trimPrecision(uf[1]);
      let sensitiveCoefficient = uf[2];

      return [
        `UB${i + 1}`,
        () => (
          <>
            {uncertaintyFactor.name}
            <br />
            {`${uncertaintyFactorValue?.replaceAll("#", " ")}`}
          </>
        ),
        uncertaintyFactor.distribution,
        "ω",
        `Sensitivity Coeff. = ${sensitiveCoefficient}`,
        `${uncertaintyFactorValue}||${uf[4]?.formula}//${uf[4]?.resolvedFormula}`,
      ];
    }),
  };
};

const resolveUncertaintyRows = (
  standardRanges,
  uncertaintyFactors,
  uncertaintiesMap,
  id,
  fallbackUnit,
  supportiveRanges,
  readings,
  rels,
  datasheetId,
  instrumentId,
  tableId,
  rangeCol
) => {
  let rows = [];

  // 1. process by standard instruments
  for (let i = 0; i < standardRanges.length; i++) {
    for (let j = 0; j < uncertaintyFactors.length; j++) {
      if (
        uncertaintiesMap[standardRanges[i][0]]?.includes(
          `${uncertaintyFactors[j].id}`
        )
      ) {
        // 1. resolve source and standard coefficient formulas
        let selectors = {
          standard: standardRanges[i][0],
          standardRange: standardRanges[i][1],
          datasheet: datasheetId,
          srfInstrument: datasheetId,
          instrument: instrumentId,
          datasheeetStaticTable: tableId,
          datasheetStaticReading: id,
        };
        let referenceData = {
          readingRow: readings,
        };
        let value = resolveUncertaintyValue(
          uncertaintyFactors[j],
          selectors,
          referenceData,
          fallbackUnit
        );

        // 2. if factor is linked to reading column the take value+unit from reading row
        if (rels[uncertaintyFactors[j].id]) {
          let _value = readings[rels[uncertaintyFactors[j].id]][0];
          _value = String(_value).replace(/[$]+/, "");
          value[0] = `${
            _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
          }`;
        }
        rows.push([uncertaintyFactors[j], ...value]);
      }
    }
  }

  // 2. process by supportive instruments
  if (supportiveRanges) {
    for (let j = 0; j < uncertaintyFactors.length; j++) {
      if (
        uncertaintiesMap[supportiveRanges?.standardId]?.includes(
          `${uncertaintyFactors[j].id}`
        )
      ) {
        let selectors = {
          instrument: instrumentId,
          srfInstrument: datasheetId,
          datasheet: datasheetId,
          datasheeetStaticTable: tableId,
          datasheetStaticReading: id,
          standard: supportiveRanges?.standardId,
          standardRange: supportiveRanges?.id,
        };
        let referenceData = {
          readingRow: readings,
        };
        let value = resolveUncertaintyValue(
          uncertaintyFactors[j],
          selectors,
          referenceData,
          fallbackUnit
        );

        // 2. if factor is linked to reading column the take value+unit from reading row
        if (rels[uncertaintyFactors[j].id]) {
          let _value = readings[rels[uncertaintyFactors[j].id]][0];
          _value = String(_value).replace(/[$]+/, "");
          value[0] = `${
            _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
          }`;
        }
        rows.push([uncertaintyFactors[j], ...value]);
      }
    }
  }

  // 3. process common uncertainty factors
  for (let j = 0; j < uncertaintyFactors.length; j++) {
    if (uncertaintiesMap[0]?.includes(`${uncertaintyFactors[j].id}`)) {
      let selectors = {
        instrument: instrumentId,
        srfInstrument: datasheetId,
        datasheet: datasheetId,
        datasheeetStaticTable: tableId,
        datasheetStaticReading: id,
      };
      let referenceData = {
        readingRow: readings,
      };
      let value = resolveUncertaintyValue(
        uncertaintyFactors[j],
        selectors,
        referenceData,
        fallbackUnit
      );

      // 2. if factor is linked to reading column the take value+unit from reading row
      if (rels[uncertaintyFactors[j].id]) {
        let _value = readings[rels[uncertaintyFactors[j].id]][0];
        _value = String(_value).replace(/[$]+/, "");
        value[0] = `${
          _value + "#" + readings[rels[uncertaintyFactors[j].id]][1]
        }`;
      }
      rows.push([uncertaintyFactors[j], ...value]);
    }
  }

  return rows;
};

const calcUncertainty = (
  uncertaintyFactorRows,
  cmcs,
  staticTable,
  datasheetDetails,
  datasheetReading,
  unitMap,
  baseValues,
  allKFactorReferenceData,
  maxReadingRangeValue,
  minReadingRangeValue,
  allReadingRangeVales
) => {
  // 1. calculate sum of uncertainty contribution
  let sumOfUncertaintyContribution = 0;
  uncertaintyFactorRows?.forEach((element) => {
    let uncertaintyContribution = element["uncertaintyContribution"];
    let [uncertaintyContributionValue, uncertaintyContributionUnit] =
      uncertaintyContribution?.split("#");
    uncertaintyContributionValue = Number(uncertaintyContributionValue);
    sumOfUncertaintyContribution += Math.pow(uncertaintyContributionValue, 2);
  });

  // 2. calculate combined uncertainty
  let combinedUncertainty = Math.sqrt(sumOfUncertaintyContribution);

  // 2.1 calculate effective degrees of freedom
  /*
        Steps  : calculate effective degree  of freedom 
        Formula :  Combine uncertainty(UC)^4 /( Uub1^4/Degree of freedom +Uub2^4/Degree of freedom + …)
    */
  let effectiveDegreesOfFreedom = 0;
  uncertaintyFactorRows.forEach((element) => {
    if (element["degreesOfFreedom"] !== "∞") {
      let uncertaintyContribution = element["uncertaintyContribution"];
      let [uncertaintyContributionValue, uncertaintyContributionUnit] =
        uncertaintyContribution?.split("#");
      uncertaintyContributionValue = Number(uncertaintyContributionValue);
      let degreeOfFreedom = Number(element["degreesOfFreedom"]);
      effectiveDegreesOfFreedom +=
        Math.pow(uncertaintyContributionValue, 4) / degreeOfFreedom;
    }
  });

  if (effectiveDegreesOfFreedom > 0) {
    effectiveDegreesOfFreedom =
      Math.pow(combinedUncertainty, 4) / effectiveDegreesOfFreedom;
  } else {
    effectiveDegreesOfFreedom = "Infinity";
  }
  // 2.2 calculate K factor value
  let KDegreeOfFreedom =
    effectiveDegreesOfFreedom > 30
      ? "Infinity"
      : Math.ceil(effectiveDegreesOfFreedom);
  let K = allKFactorReferenceData?.find(
    (ele) => ele.degreeOfFreedom >= KDegreeOfFreedom
  )?.value;

  // 3. calculate expanded uncertainty
  let expandedUncertainty = Math.abs(combinedUncertainty * K);

  let [
    finalExpandedUncertainty,
    expandedUncertaintyInDesiredUnit,
    lowerCMC,
    higherCMC,
    cmcFromRange,
    cmcToRange,
    additionalCmcData,
  ] = finalizeUncertaintyWithCMC(
    Number(expandedUncertainty),
    staticTable,
    datasheetDetails,
    datasheetReading,
    cmcs,
    unitMap,
    baseValues,
    maxReadingRangeValue,
    minReadingRangeValue,
    allReadingRangeVales
  );

  // 3.1 make uncertainty absolute
  finalExpandedUncertainty = Math.abs(Number(finalExpandedUncertainty));

  // 4. append respective units to values
  let desiredCombinedUncertaintyUnit = unitMap[1] ? unitMap[2] : unitMap[0];
  let desiredUncertaintyUnit = unitMap[0];

  combinedUncertainty =
    combinedUncertainty + "#" + desiredCombinedUncertaintyUnit;
  expandedUncertainty =
    expandedUncertainty + "#" + desiredCombinedUncertaintyUnit;

  if (
    desiredUncertaintyUnit.includes("%") &&
    !desiredUncertaintyUnit.includes("%RH")
  ) {
    expandedUncertainty =
      expandedUncertaintyInDesiredUnit +
      "#" +
      desiredUncertaintyUnit +
      ` (${expandedUncertainty})`;
  }
  if (String(finalExpandedUncertainty).toLowerCase() === "infinity") {
    finalExpandedUncertainty = "--";
  } else {
    finalExpandedUncertainty =
      finalExpandedUncertainty + "#" + desiredUncertaintyUnit;
  }

  return [
    combinedUncertainty,
    effectiveDegreesOfFreedom,
    K,
    expandedUncertainty,
    finalExpandedUncertainty,
    lowerCMC,
    higherCMC,
    cmcFromRange,
    cmcToRange,
    additionalCmcData,
  ];
};

const getBestCMCRange = (
  cmcValues,
  unitMap,
  datasheetReading,
  rangeCol,
  minReadingRangeValue,
  maxReadingRangeValue,
  allReadingRangeVales,
  baseValues,
  lc
) => {
  let selectedRange = []; // [minValue, maxValue]
  let selectedCMC = null;
  let invalidCmcs = false;
  let lowerCMC = null,
    higherCMC = null;
  let additionalCmcData = "",
    cmcFromRange = null,
    cmcToRange = null;

  let cmcData = {};

  for (let i = 0; i < cmcValues.length; i++) {
    if (
      cmcValues[i]?.fromRange?.[0] === undefined ||
      cmcValues[i]?.toRange?.[0] === undefined
    ) {
      invalidCmcs = true;
      continue;
    }
    if (
      !isCovertPossible(
        cmcValues[i]?.fromRange?.[1],
        unitMap[1] ? unitMap[2] : unitMap[0]
      ) ||
      !isCovertPossible(
        cmcValues[i]?.toRange?.[1],
        unitMap[1] ? unitMap[2] : unitMap[0]
      )
    )
      continue;

    let rangeValue = datasheetReading[rangeCol]?.[0];
    let rangeUnit = datasheetReading[rangeCol]?.[1];
    rangeValue = convertUnit(
      Number(rangeValue),
      rangeUnit,
      cmcValues[i]?.fromRange?.[1]
    );

    if (cmcValues[i]?.fromRange[1] !== cmcValues[i]?.toRange[1]) {
      cmcValues[i].toRange[0] = convertUnit(
        Number(cmcValues[i]?.toRange[0]),
        cmcValues[i]?.toRange[1],
        cmcValues[i]?.fromRange[1]
      );
    }

    // rank cmc values:, set 0 value for each index, length is cmcValues length
    let cmcRankings = {};

    // Populate the object with keys from 0 to 9 and values as 0
    for (let i = 0; i < cmcValues?.length; i++) {
      cmcRankings[i] = 0;
    }

    for (let i = 0; i < allReadingRangeVales.length; i++) {
      for (let j = 0; j < cmcValues.length; j++) {
        let otherReadingsRangeValue = convertUnit(
          Number(allReadingRangeVales[i]),
          rangeUnit,
          cmcValues[j]?.fromRange?.[1]
        );
        if (
          otherReadingsRangeValue >= cmcValues[j]?.fromRange[0] &&
          otherReadingsRangeValue <= cmcValues[j]?.toRange[0]
        ) {
          cmcRankings[j] = cmcRankings[j] ? cmcRankings[j] + 1 : 1;
        }
      }
    }
    // sort CMC ranking in values based on ranking
    cmcRankings = Object.entries(cmcRankings).sort((a, b) => {
      // Sort by value in descending order
      if (b[1] !== a[1]) {
        return b[1] - a[1];
      } else {
        // If values are equal, maintain original order of keys
        return a[0] - b[0];
      }
    });
    let rankedCMCIndexes = Object.values(cmcRankings).map((e) => e[0]);

    // select cmc value for current datasheet rangeValue
    for (let i = 0; i < rankedCMCIndexes.length; i++) {
      let index = Number(rankedCMCIndexes[i]);
      if (
        rangeValue >= cmcValues[index]?.fromRange[0] &&
        rangeValue <= cmcValues[index]?.toRange[0]
      ) {
        if (
          selectedRange[0] != null &&
          (selectedRange[0] != cmcValues[index]?.fromRange?.[0] ||
            selectedRange[1] != cmcValues[index]?.toRange?.[0])
        ) {
          continue;
        }
        selectedCMC = cmcValues[index];
        selectedRange = [
          cmcValues[index]?.fromRange?.[0],
          cmcValues[index]?.toRange?.[0],
        ];

        // exit control: if any of the below condition maches then break
        if (lc == cmcValues[index]?.lc) {
          break;
        }
      }
    }
  }

  if (selectedCMC) {
    if (selectedCMC?.mode) {
      additionalCmcData = `\nMode: ${selectedCMC?.mode}`;
    }
    if (selectedCMC?.parameter) {
      additionalCmcData =
        additionalCmcData + `\nParameter: ${selectedCMC?.parameter}`;
    }
    if (selectedCMC?.paratype) {
      additionalCmcData =
        additionalCmcData + `\nParatype: ${selectedCMC?.paratype}`;
    }

    let lowerCmcValue = selectedCMC.lowerCmc[0];
    let lowerCmcValueUnit = selectedCMC.lowerCmc[1];
    let higherCmcValue = selectedCMC.higherCmc[0];
    let higherCmcValueUnit = selectedCMC.higherCmc[1];

    let desiredCombinedUncertaintyUnit = unitMap[1] ? unitMap[2] : unitMap[0];

    cmcFromRange = `${selectedCMC?.fromRange[0]}#${desiredCombinedUncertaintyUnit}`;
    cmcToRange = `${selectedCMC?.toRange[0]}#${desiredCombinedUncertaintyUnit}`;

    // 1. convert higher and lower cmc values to desired unit
    if (lowerCmcValueUnit?.includes("%") && lowerCmcValueUnit !== "%RH") {
      let baseValue = lowerCmcValueUnit.toLowerCase().includes("fsd")
        ? baseValues?.percentFSDBaseValue
        : baseValues?.percentBaseValue;

      lowerCMC = (lowerCmcValue || "") + "#" + (lowerCmcValueUnit || "");

      lowerCmcValue = (lowerCmcValue * baseValue) / 100;

      lowerCMC =
        lowerCMC + ` (${lowerCmcValue}#${desiredCombinedUncertaintyUnit})`;
    } else {
      lowerCmcValue = convertUnitWrap(
        lowerCmcValue,
        lowerCmcValueUnit,
        desiredCombinedUncertaintyUnit
      );
      lowerCMC = (lowerCmcValue || "") + "#" + (lowerCmcValueUnit || "");
    }

    if (higherCmcValueUnit?.includes("%") && higherCmcValueUnit !== "%RH") {
      let baseValue = lowerCmcValueUnit.toLowerCase().includes("fsd")
        ? baseValues?.percentFSDBaseValue
        : baseValues?.percentBaseValue;
      higherCMC = (higherCmcValue || "") + "#" + (higherCmcValueUnit || "");
      higherCmcValue = (higherCmcValue * baseValue) / 100;
      higherCMC =
        higherCMC + ` (${higherCmcValue}#${desiredCombinedUncertaintyUnit})`;
    } else {
      higherCmcValue = convertUnitWrap(
        higherCmcValue,
        higherCmcValueUnit,
        desiredCombinedUncertaintyUnit
      );
      higherCMC = (higherCmcValue || "") + "#" + (higherCmcValueUnit || "");
    }

    cmcData["higherCmcValue"] = higherCmcValue;
    cmcData["lowerCmcValue"] = lowerCmcValue;
  }

  cmcData = {
    ...cmcData,
    lowerCMC,
    higherCMC,
    cmcFromRange,
    cmcToRange,
    additionalCmcData,
    invalidCmcs,
  };

  return cmcData;
};

const finalizeUncertaintyWithCMC = (
  uncertainty,
  staticTable,
  datasheetDetails,
  datasheetReading,
  cmcs,
  unitMap,
  baseValues,
  maxReadingRangeValue,
  minReadingRangeValue,
  allReadingRangeVales
) => {
  let finalUncertainty = null;
  let cmcData = {};
  if (staticTable) {
    let defaultConfig = staticTable["defaultConfiguration"] || {};
    let rangeCol = defaultConfig["rangeCol"];

    // 1. find CMC values
    let cmcValues = cmcs.map((cmc) => {
      // 1.1 check unit measurement compatibility
      if (
        cmc.fromRange[1] !== (unitMap[1] ? unitMap[2] : unitMap[0]) &&
        (!isCovertPossible(
          cmc.fromRange[1],
          unitMap[1] ? unitMap[2] : unitMap[0]
        ) ||
          !isCovertPossible(
            cmc.toRange[1],
            unitMap[1] ? unitMap[2] : unitMap[0]
          ))
      ) {
        return null;
      }

      // 1.2 if compatible then continue
      return {
        mode: cmc.mode,
        parameter: cmc.parameter,
        paratype: cmc.paratype,
        lc: cmc.lc,
        fromRange: [
          cmc.fromRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.fromRange[0]),
                  cmc.fromRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          unitMap[1] ? unitMap[2] : unitMap[0],
        ],
        toRange: [
          cmc.toRange[0]
            ? Number(
                convertUnitWrap(
                  Number(cmc.toRange[0]),
                  cmc.toRange[1],
                  unitMap[1] ? unitMap[2] : unitMap[0]
                )
              )
            : undefined,
          unitMap[1] ? unitMap[2] : unitMap[0],
        ],
        lowerCmc: [cmc.lowerCmc[0], cmc.lowerCmc[1]],
        higherCmc: [cmc.higherCmc[0], cmc.higherCmc[1]],
      };
    });

    // 2. filter null values
    cmcValues = cmcValues.filter((cmc) => cmc !== null);

    // 3. find CMC values, convert to respective units and compare with uncertainty, and find final uncertainty
    if (uncertainty !== undefined && uncertainty !== null && rangeCol) {
      cmcData = getBestCMCRange(
        cmcValues,
        unitMap,
        datasheetReading,
        rangeCol,
        minReadingRangeValue,
        maxReadingRangeValue,
        allReadingRangeVales,
        baseValues,
        datasheetDetails?.lc
      );

      finalUncertainty = uncertainty;
      if (defaultConfig?.uncertaintyReferenceType == 1) {
        finalUncertainty = cmcData?.higherCmcValue
          ? cmcData?.higherCmcValue
          : cmcData?.lowerCmcValue;
      } else if (cmcData?.higherCmcValue) {
        if (cmcData?.higherCmcValue > uncertainty) {
          finalUncertainty = cmcData?.higherCmcValue;
        } else if (
          cmcData?.lowerCmcValue &&
          cmcData?.lowerCmcValue > uncertainty
        ) {
          finalUncertainty = cmcData?.lowerCmcValue;
        }
      } else if (cmcData?.lowerCmcValue > uncertainty) {
        finalUncertainty = cmcData?.lowerCmcValue;
      }

      if (cmcData?.invalidCmcs) {
        toast.warning("Please Correct the CMC values");
      }
    } else {
      toast.warning("Please set Range Column using Template, to compare cmc!");
    }

    // 4. calculate uncertainty percentage if applicable (if unit of uncertainty is in %)
    if (unitMap[0]?.toLowerCase()?.includes("%fsd")) {
      let rangeValue = datasheetDetails?.ranges?.split("|")?.[1];
      rangeValue = rangeValue?.split("#")?.[0];
      let baseValue = Number(rangeValue);

      uncertainty = convertUnit(
        Number(uncertainty),
        unitMap[2]?.includes("%") ? "%" : unitMap[2],
        unitMap[0]?.includes("%") ? "%" : unitMap[0],
        baseValue
      );

      finalUncertainty = convertUnit(
        Number(finalUncertainty),
        unitMap[2]?.includes("%") ? "%" : unitMap[2],
        unitMap[0]?.includes("%") ? "%" : unitMap[0],
        baseValue
      );
    } else if (unitMap[0]?.includes("%") && !unitMap[0]?.includes("%RH")) {
      let baseValue = datasheetReading[rangeCol]?.[0];
      uncertainty = convertUnit(
        Number(uncertainty),
        unitMap[2],
        unitMap[0],
        baseValue
      );

      finalUncertainty = convertUnit(
        Number(finalUncertainty),
        unitMap[2],
        unitMap[0],
        baseValue
      );
    }
  }

  return [
    trimPrecision(finalUncertainty),
    uncertainty,
    cmcData?.lowerCMC,
    cmcData?.higherCMC,
    cmcData?.cmcFromRange,
    cmcData?.cmcToRange,
    cmcData?.additionalCmcData,
  ];
};

const processBudgetRows = ({
  id,
  inputs,
  unit,
  supportiveRanges,
  uncertaintiesMap,
  typeA,
  cmcs,
  staticTable,
  datasheetDetails,
  readings,
  standardRanges,
  allKFactorReferenceData,
  maxReadingRangeValue,
  minReadingRangeValue,
  allReadingRangeVales,
  datasheetId,
  instrumentId,
  tableId,
}) => {
  if (!inputs) return {};

  let reverseMap = {};

  let standardRangeIds = readings.standardRanges.map((e) => e);

  let typebRels = { ...staticTable.defaultConfiguration.typeb.relations };

  // 1. prepare base values for % unit conversions
  let baseValues = { percentBaseValue: 0, percentFSDBaseValue: 0 };
  let rangeCol = (staticTable["defaultConfiguration"] || {})?.["rangeCol"];

  if (rangeCol) {
    baseValues["percentBaseValue"] = readings[rangeCol][0];

    let rangeValue = datasheetDetails?.ranges?.split("|")?.[1];
    rangeValue = rangeValue?.split("#")?.[0];
    let baseValue = Number(rangeValue);
    baseValues["percentFSDBaseValue"] = baseValue;

    let masterRangeValue = standardRanges[0]?.masterEQPRange?.to;
    baseValues["factorFSDBaseValue"] = Number(masterRangeValue?.split("#")?.[0]) || 1;
  }

  Object.keys(typebRels).map((e) => (reverseMap[typebRels[e]] = e));
  let uncertaintyFactorRows = resolveUncertaintyRows(
    standardRangeIds,
    inputs,
    uncertaintiesMap,
    id,
    unit[1] ? unit[2] : unit[0],
    supportiveRanges,
    readings,
    reverseMap,
    datasheetId,
    instrumentId,
    tableId,
    rangeCol
  );

  let rows = uncertaintyFactorRows.map((uf, i) => {
    let uncertaintyFactorValue = uf[1];
    let [ufValue, ufUit] = uncertaintyFactorValue?.split("#") || [null, null];
    ufValue = Number(ufValue);

    let sensitiveCoefficient = uf[2];
    let valueAdjustment = uf[3] || "0";

    // apply limits to uncertanity factor value
    let limitPercent = Number(uf[0]?.limits || 100);
    let ufLimitXi = ufValue * (limitPercent / 100);

    let uncertaintyContribution = calculateUncertaintyContribution(
      ufLimitXi + `#${ufUit}`,
      Number(uf[0].formula),
      sensitiveCoefficient,
      valueAdjustment,
      unit[1] ? unit[2] : unit[0],
      baseValues
    );

    return {
      factorLabel: () => (
        <>
          UB{i + 1}
          <br />
          {uf[0].name}
        </>
      ),
      estimate: uncertaintyFactorValue,
      limitXi: ufLimitXi + "#" + ufUit,
      probabilityDistribution: uf[0].distribution,
      standardUncertainty: `${Number(ufLimitXi / uf[0].formula)}` + "#" + ufUit,
      sensitiveCoefficient: `${sensitiveCoefficient}`,
      uncertaintyContribution: `${uncertaintyContribution}`,
      degreesOfFreedom: uf[0]?.degreesOfFreedom || "∞",
    };
  });

  if (typeA.meanOfStdDev) {
    let sd = Number(typeA.sd.split("#")[0]);
    let sdUnit = typeA.sd.split("#")[1];

    let uc = calculateUncertaintyContribution(
      `${sd}#${sdUnit}`,
      Math.sqrt(Number(typeA.n)),
      "1",
      "0",
      unit[1] ? unit[2] : unit[0],
      baseValues
    );

    rows = rows.concat({
      factorLabel: "Repeatability",
      estimate: `${sd}#${sdUnit}`,
      limitXi: `${sd}#${sdUnit}`,
      probabilityDistribution: `√${typeA.n}`,
      standardUncertainty: `${
        Number(sd) / Math.sqrt(Number(typeA.n))
      }#${sdUnit}`,
      sensitiveCoefficient: `1`,
      uncertaintyContribution: `${uc}`,
      degreesOfFreedom: `${typeA.n - 1}`,
    });
  }

  let [
    combinedUncertainty,
    effectiveDegreesOfFreedom,
    K,
    uncertainty,
    finalExpandedUncertainty,
    lowerCMC,
    higherCMC,
    cmcFromRange,
    cmcToRange,
    additionalCmcData,
  ] = calcUncertainty(
    rows,
    cmcs,
    staticTable,
    datasheetDetails,
    readings,
    unit,
    baseValues,
    allKFactorReferenceData,
    maxReadingRangeValue,
    minReadingRangeValue,
    allReadingRangeVales
  );

  let rowsToRender = [];

  rows.forEach((row, i) => {
    rowsToRender.push([
      row["factorLabel"],
      trimPrecision(row["estimate"]),
      trimPrecision(row["limitXi"]),
      row["probabilityDistribution"],
      trimPrecision(row["standardUncertainty"]),
      row["sensitiveCoefficient"],
      trimPrecision(row["uncertaintyContribution"]),
      row["degreesOfFreedom"],
    ]);
  });

  rowsToRender = rowsToRender.concat([
    [
      "",
      `Uc`,
      ``,
      ``,
      `${trimPrecision(combinedUncertainty)}`,
      `1`,
      `${trimPrecision(combinedUncertainty)}`,
      `∞`,
    ],
    [
      "",
      `Expanded Uncertainty`,
      `K=`,
      `${K}`,
      ``,
      ``,
      `${finalExpandedUncertainty}`,
      `∞`,
    ],
  ]);

  return {
    rows: rowsToRender,
    extra: {
      uc: combinedUncertainty,
      freedom: effectiveDegreesOfFreedom,
      uncertainty,
      finalExpandedUncertainty,
      lowerCMC,
      higherCMC,
      cmcFromRange,
      cmcToRange,
      additionalCmcData,
    },
  };
};

const processReportRows = async (
  table,
  datasheetDetails,
  readings,
  uncertaintiesMap,
  cmcs,
  tableId,
  datasheetId,
  instrumentId,
  allKFactorReferenceData,
  maxReadingRangeValue,
  minReadingRangeValue,
  allReadingRangeVales
) => {
  // fun: process and preapre data for typeA, typeB and budget

  let tableA1 = [];
  let tableA2 = [];
  let staticTable = parseTable(table);
  let parsedReadings = parseReadings(readings);

  let standardRanges = await fetchStandardRanges(
    readings
      .map((e) => e.standardRanges?.split(":")?.[1]?.split(",")?.[0])
      .flat(Infinity)
      .filter((e) => e)
  );
  let supportiveRanges = (
    await fetchSupprtiveRanges(
      parsedReadings.map((reading) => reading.supportiveRanges)
    )
  )[0]?.[1];
  supportiveRanges = supportiveRanges || [];
  let uncertaintyFactors = (
    await fetchUncertaintyFactors(parsedReadings, uncertaintiesMap)
  )[0][1];
  cmcs = parseCMCs(cmcs);
  if (staticTable) {
    tableA1 = getTypeAColumns(staticTable);
    tableA2 = getTypeAColumns(staticTable, true);
  }

  let reportRows = [];
  if (parsedReadings.length > 0) {
    await generateTableData(
      uncertaintyFactors,
      parsedReadings,
      tableId,
      datasheetId,
      instrumentId,
      uncertaintiesMap
    );

    reportRows = await Promise.all(
      parsedReadings.map(async (readingRow, i) => {
        let uncertaintyUnit =
          readingRow.unitMap?.uncertainty?.split(UNIT_ROW_VALUE_PREFIX)[1] ||
          "";

        let rangeCol =
          (staticTable["defaultConfiguration"] || {})?.["rangeCol"] || "";
        let rangeColUnit =
          readingRow.unitMap?.[rangeCol]?.split(UNIT_ROW_VALUE_PREFIX)[1] || "";

        let typeA1 = processTypeARows({
          inputs: tableA1
            .map((a) => readingRow[a][0])
            ?.filter((e) => !isNaN(e)),
          unit:
            tableA1.length > 0
              ? readingRow.unitMap[tableA1[0]]?.split(
                  UNIT_ROW_VALUE_PREFIX
                )?.[1]
              : "",
        });

        let typeA2 = processTypeARows({
          inputs: tableA2
            .map((a) => readingRow[a][0])
            ?.filter((e) => !isNaN(e)),
          unit:
            tableA2.length > 0
              ? readingRow.unitMap[tableA2[0]]?.split(
                  UNIT_ROW_VALUE_PREFIX
                )?.[1]
              : "",
        });

        let typeB = readingRow.standardRanges?.length
          ? processTypeBRows({
              id: `${readingRow.id}`,
              inputs: uncertaintyFactors,
              fallbackUnit: rangeColUnit,
              supportiveRanges: readingRow.supportiveRanges[1]
                ? supportiveRanges[readingRow.supportiveRanges[1]]
                : undefined,
              uncertaintiesMap: uncertaintiesMap,

              staticTable,
              readings: readingRow,
              datasheetId: datasheetId,
              instrumentId: instrumentId,
              tableId: tableId,
            })
          : {};

        let budget = readingRow.standardRanges?.length
          ? processBudgetRows({
              id: `${readingRow.id}`,
              inputs: uncertaintyFactors,
              unit: [
                readingRow.unitMap?.uncertainty?.split(
                  UNIT_ROW_VALUE_PREFIX
                )[1] || "",
                uncertaintyUnit?.includes("%") ? true : false,
                tableA1.length > 0
                  ? readingRow.unitMap[tableA1[0]]?.split(
                      UNIT_ROW_VALUE_PREFIX
                    )?.[1]
                  : rangeColUnit,
              ],
              supportiveRanges: readingRow.supportiveRanges[1]
                ? supportiveRanges[readingRow.supportiveRanges[1]]
                : undefined,
              uncertaintiesMap: uncertaintiesMap,
              typeA: typeA2?.meanOfStdDev
                ? typeA1.meanOfStdDev > typeA2.meanOfStdDev
                  ? typeA1
                  : typeA2
                : typeA1,
              cmcs,
              staticTable,
              datasheetDetails,
              readings: readingRow,
              standardRanges: [
                standardRanges[Number(readingRow.standardRanges[0][1])],
              ],
              allKFactorReferenceData,
              maxReadingRangeValue,
              minReadingRangeValue,
              allReadingRangeVales,
              datasheetId,
              instrumentId,
              tableId,
            })
          : {};

        return [
          [
            tableA1?.length > 0 ? typeA1.rows : [],
            tableA2?.length > 0 ? typeA2.rows : [],
          ],
          typeB.rows,
          [budget.rows, budget.extra],
          readingRow.id,
        ];
      })
    );
  }
  return reportRows;
};

const fetchKFactorReferenceData = async () => {
  let res = null;
  try {
    const sqlQuery = {
      query: `SELECT kr.* FROM KFactorReference kr JOIN settings s ON kr.fractionPercent = s.value WHERE s.keyName = 'KFactorFactionPercent' AND s.status = 1`,
    };

    res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
    res = res.data;
  } catch (error) {
    console.log("KFactorReference data fetching error: ", error);
  }

  return res;
};

export const fetchData = async (
  setReportDetails,
  tableId,
  datasheetId,
  instrumentId,
  maxReadingRangeValue,
  minReadingRangeValue,
  allReadingRangeVales,
  navigate,
  origin = "origin:uncertaintyReport"
) => {
  let data = {};

  let allKFactorReferenceData = await fetchKFactorReferenceData();

  let arrayData = [
    ...(await fetchTables(tableId)),
    ...(await fetchDatasheetDetails(datasheetId)),
    ...(await fetchReadings(datasheetId, tableId)),
    ...(await fetchUncertaintiesMap(instrumentId)),
    ...(await fetchCmcs(instrumentId, datasheetId)),
  ];
  arrayData.map((e) => (data[e[0]] = e[1]));
  if (!data.reading || data.reading?.length == 0) {
    if (origin == "origin:fromDatasheet") return null;

    window.confirm("Please add units and reading rows first!");
    navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
    window.location.reload(false);
  }
  data.parsedReadings = parseReadings(data.reading);

  for (let e of data.parsedReadings) {
    if (!e.unitMap) {
      window.confirm("Please add unit row for each reading row!");
      navigate(`/datasheet/edit/${datasheetId}/${instrumentId}`);
      window.location.reload(false);
    }
  }

  arrayData.map((e) => (data[e[0]] = e[1]));

  allReadingRangeVales = allReadingRangeVales.split(",").map((e) => Number(e));
  let preparedReportRows = await processReportRows(
    data.table,
    data.datasheetDetails,
    data.reading,
    data.uncertaintiesMap,
    data.cmcs,
    tableId,
    datasheetId,
    instrumentId,
    allKFactorReferenceData,
    maxReadingRangeValue,
    minReadingRangeValue,
    allReadingRangeVales
  );

  let finalResult = {};

  await Promise.all([
    ...preparedReportRows.map((uncertainty) => {
      let input = {};
      if (
        uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== undefined &&
        uncertainty?.[2]?.[1]?.finalExpandedUncertainty !== null
      )
        input.uncertainty = uncertainty?.[2]?.[1]?.finalExpandedUncertainty;
      Object.entries(data.table).map((col) => {
        if (
          `${col[1]}`.includes(UNCERTAINTY_LINK) &&
          uncertainty[0] &&
          (uncertainty[0][0] || uncertainty[0][1]) &&
          (uncertainty[0][0].length > 0 || uncertainty[0][1].length > 0) &&
          (uncertainty[0][0]?.[uncertainty[0][0].length - 2]?.[1]?.replaceAll(
            " ",
            "#"
          ) ||
            uncertainty[0][1]?.[uncertainty[0][1].length - 2]?.[1]?.replaceAll(
              " ",
              "#"
            ))
        ) {
          if (`${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea1")
            input[`${col[0]}`] = uncertainty[0][0]?.[
              uncertainty[0][0].length - 2
            ]?.[1]?.replaceAll(" ", "#");
          else if (
            `${col[1]}`.split(UNCERTAINTY_LINK)[1].toLowerCase() === "typea2"
          )
            input[`${col[0]}`] = uncertainty[0][1]?.[
              uncertainty[0][1].length - 2
            ]?.[1]?.replaceAll(" ", "#");
        }
      });

      return Object.keys(input).length > 0
        ? axiosWithToken.patch(
            BASE_URL +
              `datasheetStaticReadings/${uncertainty[uncertainty.length - 1]}`,
            input
          )
        : [];
    }),
  ]);

  setReportDetails && setReportDetails([...preparedReportRows]);

  if (origin == "origin:fromDatasheet") {
    return finalResult;
  } else {
    return null;
  }
};
