import { Box, Button, Modal, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { showComponent } from "../../helper/helpers";
import { modalStyle, multiValueModalStyle } from "../../../constants/srfConstants";
import { notifiy } from "../../notification/Notification";
import { BASE_URL } from "../../../global";
import { useEffect, useState } from "react";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// TODO: replace with shubhams code for multivalue selection
export const RenderMultivalueModal = (props) => {
  const {
    multiValueData,
    multiValueModalOpen,
    setMultiValueModalOpen,
    readingRows,
    updateCellValue,
    unitFilter,
    instrumentArray,
  } = props;

  const [unitsByDisciplineID, setUnitsByDisciplineID] = useState([]);

  useEffect(() => {
    const instrumentID = readingRows?.[multiValueData?.rowIndex]?.instrumentId;
    const disciplineID = instrumentArray?.find(
      (inst) => inst?.id === instrumentID
    )?.disciplineId;
    const newUnits =
      unitFilter?.filter((unit) => unit?.disciplineId === disciplineID) ||
      unitFilter;
    newUnits.unshift("");
    setUnitsByDisciplineID(newUnits);
  }, [multiValueData?.rowIndex, readingRows, instrumentArray, unitFilter]);

  if (multiValueData?.rowIndex != undefined && multiValueData?.key != undefined)
    return (
      <Modal
        open={multiValueModalOpen}
        onClose={() => {
          setMultiValueModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={multiValueModalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "5px",
            }}
          >
            <h5>Update Multiple values</h5>
            <Button
              variant="contained"
              size="small"
              onClick={() => setMultiValueModalOpen(false)}
            >
              X
            </Button>
          </Box>
          <table className="srf-multivalue-table"  style={{width:"100%"}}>
            <tr>
              {["ranges", "operatingRange"].includes(multiValueData.key) ? (
                <th>Min Value</th>
              ) : (
                ""
              )}
              <th>
                {["ranges", "operatingRange"].includes(multiValueData.key)
                  ? "Max Value"
                  : "Value"}
              </th>
              {/* <th>Unit</th> */}
              <th></th>
            </tr>
            <tbody>
              {readingRows[multiValueData.rowIndex]?.[multiValueData.key]
                ?.split("||")
                .map((e, idx) => {

                  const [minValue = "", maxValue = ""] = e?.split("|") || [];
                  const [minValueText = "", minUnit = ""] =
                    minValue?.split("#") || [];
                  const [maxValueText = "", maxUnit = ""] =
                    maxValue?.split("#") || [];

                  const isDualInput = ["ranges", "operatingRange"].includes(
                    multiValueData.key
                  );

                  const updateValue = (type, index, value, unit) => {
                    let values =
                      readingRows[multiValueData.rowIndex]?.[
                        multiValueData.key
                      ]?.split("||") || [];
                    let pair = values[index]?.split("|") || ["", ""];
                    let parts = pair[type]?.split("#") || ["", ""];
                    parts[0] = value;
                    parts[1] = unit;
                    pair[type] = parts.join("#");
                    values[index] = pair.join("|");
                    updateCellValue(
                      multiValueData.rowIndex,
                      multiValueData.key,
                      values.join("||")
                    );
                  };

                  return (
                    <tr key={idx}>
                      {isDualInput ? (
                        <>
                          <td className="border-0">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control no-border"
                                value={minValueText}
                                onChange={(e) =>
                                  updateValue(0, idx, e.target.value, minUnit)
                                }
                              />
                              <select
                                className="form-select no-border"
                                onChange={(e) =>
                                  updateValue(
                                    0,
                                    idx,
                                    minValueText,
                                    e.target.value
                                  )
                                }
                                value={minUnit}
                              >
                                <option value="">Select Unit</option>
                                {unitsByDisciplineID?.map((option) => (
                                  <option
                                    key={option.symbol}
                                    value={option.symbol}
                                  >
                                    {option.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td className="border-0">
                            <div className="input-group mb-3 ps-3">
                              <input
                                type="text"
                                className="form-control no-border"
                                value={maxValueText}
                                onChange={(e) =>
                                  updateValue(1, idx, e.target.value, maxUnit)
                                }
                              />
                              <select
                                className="form-select no-border"
                                onChange={(e) =>
                                  updateValue(
                                    1,
                                    idx,
                                    maxValueText,
                                    e.target.value
                                  )
                                }
                                value={maxUnit}
                              >
                                <option value="">Select Unit</option>
                                {unitsByDisciplineID?.map((option) => (
                                  <option
                                    key={option.symbol}
                                    value={option.symbol}
                                  >
                                    {option.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                        </>
                      ) : (
                        <td className="border-0">
                          <div className="input-group mb-3 px-3">
                            <input
                              type="text"
                              className="form-control no-border"
                              value={minValueText}
                              onChange={(e) =>
                                updateValue(0, idx, e.target.value, minUnit)
                              }
                            />
                            <select
                              className="form-select no-border"
                              onChange={(e) =>
                                updateValue(
                                  0,
                                  idx,
                                  minValueText,
                                  e.target.value
                                )
                              }
                              value={minUnit}
                            >
                              <option value="">Select Unit</option>
                              {unitsByDisciplineID?.map((option) => (
                                <option
                                  key={option.symbol}
                                  value={option.symbol}
                                >
                                  {option.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      )}
                      <td className="border-0"  style={{verticalAlign:"top"}}>
                        {readingRows[multiValueData.rowIndex]?.[
                          multiValueData.key
                        ]?.split("||").length > 1 &&
                          showComponent("delete_in") && (
                            <DeleteIcon
                              style={{ color: "#dc3545" }}
                              onClick={() => {
                                let tmp = readingRows[
                                  multiValueData.rowIndex
                                ]?.[multiValueData.key]
                                  ?.split("||")
                                  .filter((_, i) => i !== idx);
                                updateCellValue(
                                  multiValueData.rowIndex,
                                  multiValueData.key,
                                  tmp.join("||")
                                );
                              }}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tr>
              <td
                colSpan={
                  ["ranges", "operatingRange"].includes(multiValueData.key)
                    ? 4
                    : 2
                }
              >
                <Button
                  onClick={(e) => {
                    let ctr =
                      readingRows[multiValueData.rowIndex]?.[multiValueData.key];
                    ctr += "||";
                    updateCellValue(
                      multiValueData.rowIndex,
                      multiValueData.key,
                      ctr
                    );
                  }}
                >
                  <b style={{ fontSize: "18px" }}>ADD</b>
                  <AddIcon />
                </Button>
              </td>
            </tr>
          </table>
        </Box>
      </Modal>
    );
  else return <></>;
};

export const RenderModal = (props) => {
  const {
    modalOpen,
    handleClose,
    readingRows,
    currentRowIndex,
    setReadingRows,
    tempReading,
    DUC_ID,
    srfDetails,
  } = props;

  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  const tempReadingResult = (decision) => {
    if (decision) {
    } else {
      let newRows = [...readingRows];
      newRows[currentRowIndex] = {
        ...newRows[currentRowIndex],
        instrumentId: "",
        DUCID: "",
        serialNumber: "",
        ranges: "",
        lc: "",
        make: "",
        model: "",
        ConOfDuc: "",
        calFrequency: "",
        accuracy: "",
        calibrationType: "",
        location: "",
        locationOfInstrument: "",
        calPoint: "",
        calMethod: "",
        labCapabilities: "",
        department: "",
        operatingRange: "",
      };
      Object.keys(newRows[currentRowIndex].extraColumns).forEach((key) => {
        newRows[currentRowIndex].extraColumns[key] = "";
      });
      setReadingRows([...newRows]);
    }
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      size="md"
    >
      <Box sx={modalStyle}>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1, width: "100%" }}>
              <span>
                DUC is within the due date. Do you want to ignore or clear
                selected row?
              </span>
            </div>
            <div style={{ flexBasis: "50px" }}></div>
            <div style={{ flex: 1, width: "100%", display: "flex" }}>
              <div style={{ flex: 1, width: "100%" }}></div>
              <div style={{ flex: 1, width: "100%" }}>
                <Tooltip
                  title="Ignore Warning and adds Reading"
                  placement="top-start"
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0, ml: 2 }}
                    onClick={(e) => {
                      tempReadingResult(true);
                      axiosWithToken
                        .get(
                          BASE_URL +
                            `users?_where=(status,eq,1)~and((type,eq,1)~or((type,eq,4)${
                              srfDetails?.branch
                                ? `~and(branch,eq,${srfDetails?.branch})`
                                : ""
                            }))&fields=id`
                        )
                        .then((res) => {
                          res.data.map((e) =>
                            notifiy({
                              _from: user[0],
                              _to: e.id,
                              body: `${DUC_ID[1]}(${DUC_ID[0]}) is within Due Date, but user ${user[1]} (${user[0]}) is trying to create new SRF for Instrument!`,
                            })
                          );
                        });
                    }}
                  >
                    Ignore
                  </Button>
                </Tooltip>
              </div>
              <div style={{ flex: 1, width: "100%" }}>
                <Tooltip
                  title="Clear readings but keeps instrument"
                  placement="top-start"
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0, ml: 2 }}
                    onClick={(e) => tempReadingResult()}
                  >
                    Clear
                  </Button>
                </Tooltip>
              </div>
              <div style={{ flex: 1, width: "100%" }}></div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
